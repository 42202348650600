import firebaseConfig from "./config"

import firebase from "firebase/app"
import "firebase/firestore"
// import "firebase/functions" // !!! does not work with build command
import "firebase/auth"

let firebaseInstance

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase()
  }
  return firebaseInstance
}

class Firebase {
  constructor() {
    this.app = firebase.initializeApp(firebaseConfig)
    this.firestore = firebase.firestore()
    // this.functions = firebase.functions()
    this.auth = firebase.auth

    // *** DO NOT REMOVE the comment bellow as it is for emulator setup
    // this.firestore.settings({
    //   host: `localhost:8080`,
    //   ssl: false,
    // })
    // this.functions.useFunctionsEmulator("http://localhost:5001")
    // *** DO NOT REMOVE the comment above as it is for emulator setup
  }

  register = ({ email, password, username }) => {
    return this.auth().createUserWithEmailAndPassword(email, password)
  }

  login = ({ email, password }) => {
    return this.auth().signInWithEmailAndPassword(email, password)
  }

  async logout() {
    await this.auth().signOut()
  }
}

export default getFirebaseInstance